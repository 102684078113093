import React, { useState } from "react"
import { navigate } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TopHeader from "../components/top-header"

import { Row, Container, Col, Form, Button } from 'react-bootstrap/'
import { FaPhone, FaMap, FaEnvelope, FaFax } from 'react-icons/fa';
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"

const Iletisim = ({ intl }) => {

  function encode(data) {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }
  const [validated, setValidated] = useState(false);
  const [state, setState] = useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }
    setValidated(true);

    if (form.checkValidity() === true)
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': form.getAttribute('name'),
          ...state,
        }),
      })
        .then(() => navigate(form.getAttribute('action')))
  }
  const { namesurname, position, comp, phone, email, checkboxChecked, message, } = useState({})

  return <Layout>
    <TopHeader />
    <SEO
      title={intl.formatMessage({ id: "page_contact_title" })}
      description='Yapay Zeka Destekli MES, üretim Takip Sistemi, ekipman yönetimi ve OEE çözümleri için satış ekibimizle iletişime geçin.'
    />
    <Container>
      <h1 style={{ fontWeight: '200', paddingTop: '30px', textAlign: '-webkit-center', }}><FormattedMessage id="contact_team" /></h1>
      <p style={{ fontWeight: '200', paddingTop: '30px', textAlign: '-webkit-center', }}><FormattedMessage id="contact_desc" /></p>
      <Row className='contact-form-row'>
        <Col sm className=''>
          <Col sm style={{ alignSelf: 'center', marginTop: '0em', marginBottom: '1.4em' }} className='head-col-1'  >
            <div className='contact-form-divider'></div>
            <Row className='contact-form-row-contact-page'>
              <Col sm style={{ marginTop: '0em', marginBottom: '0em' }} className='head-col-1'  >
                <Container style={{ marginTop: '50px' }} className='contact-form-container'>
                  <Form
                    netlify
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                    name="Retmes_Customer_Contact"
                    method="post"
                    action="/success"
                    data-netlify="true"
                    data-netlify-honeypot="bot-field">
                    <input type="hidden" name="form-name" value="Retmes_Customer_Contact" />
                    <Form.Group >
                      <Form.Control required type="text" name="namesurname" value={namesurname} placeholder={intl.formatMessage({ id: "contact_form_name" })} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group >
                      <Form.Control required type="email" name="email" value={email} placeholder={intl.formatMessage({ id: "contact_form_email" })} onChange={handleChange} />
                      <Form.Control.Feedback type="invalid"><FormattedMessage id="contact_email_feedback" /></Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group >
                      <Form.Control required type="tel" pattern="[0-9]{3}[0-9]{3}[0-9]{4}" value={phone} name="phone" placeholder={intl.formatMessage({ id: "contact_form_phone" })} onChange={handleChange} />
                      <Form.Control.Feedback type="invalid"><FormattedMessage id="contact_phone_feedback" /></Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group >
                      <Form.Control type="text" name="comp" placeholder={intl.formatMessage({ id: "contact_form_comp" })} value={comp} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group >
                      <Form.Control type="text" name="position" value={position} placeholder={intl.formatMessage({ id: "contact_form_title" })} onChange={handleChange} >
                      </Form.Control>
                    </Form.Group>
                    <Form.Group >
                      <Form.Control className='form-message-field' value={message} required as="textarea" name="message" rows="5" placeholder={intl.formatMessage({ id: "contact_form_message" })} onChange={handleChange} />
                    </Form.Group>
                    <Form.Check
                      onChange={handleChange}
                      checked={checkboxChecked}
                      value={checkboxChecked ? 'No' : 'YES'}
                      name="subscribe"
                      custom
                      type='checkbox'
                      id={`custom-${'checkbox'}`}
                      label={<FormattedMessage id="form_subscribe_validations_checkbox" />}
                    />
                    <div className='contact-form-submit-button'>
                      <Button type='submit' className='retmes-detail-button'><FormattedMessage id="contact_send" /></Button>
                    </div>
                  </Form>
                </Container>
              </Col>
            </Row>
          </Col>
        </Col>
        <Col sm className='right-col-contact'  >
          <div className='contact-form-icons'>
            <div className='contact-form-items'>
              <FaMap />
              <address>Zümrütevler Mh. Nazmi İlker Sk. No:3<br />
                <br />Maltepe/İstanbul
               </address>
            </div>
            <a className='modules-link-contact' href='mailto:info@retmes.com'> <div className='contact-form-items'><FaEnvelope />info@retmes.com</div> </a>
            <a className='modules-link-contact' href='tel:+902163921642'><div className='contact-form-items'><FaFax /> +90 (216) 392 16 43</div></a>
            <a className='modules-link-contact' href='tel:+902163921642'><div className='contact-form-items'><FaPhone /> +90 (216) 392 16 42</div></a>
          </div>
        </Col>
      </Row>
      <Row style={{ marginRight: '0', marginLeft: '0', paddingBottom: '5rem' }}>
        <Col sm className='contact-form-row-contact-page'  >
          <iframe width="100%" height="300" title='Retmes Map' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12072.25870408912!2d29.2956401!3d40.8485038!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa5c8a57dda900e7!2sRetmes%20%7C%20Yapay%20Zeka%20Destekli%20MES%20%C3%87%C3%B6z%C3%BCmleri!5e0!3m2!1sen!2sus!4v1568278687908!5m2!1sen!2sus" frameborder="0" style={{ border: 0, margin: 0 }} allowfullscreen=""></iframe>
        </Col>
      </Row>
    </Container>
    <svg viewBox="0 0 1920 110" className='footer-svg'>
      <defs>
        <linearGradient id="gradient">
          <stop offset="0%" stop-color="#36526a" />
          <stop offset="100%" stop-color="#1d90af" />
        </linearGradient>
      </defs><g data-name="Layer 2"><g data-name="Layer 1"><path d="M0 90.51a1428.76 1428.76 0 0 1 161.57-51.15C670.24-88.92 969.21 140.79 1525.6 102c87.48-6.14 231.93-22.36 411.12-75.13l-4 204.28c0 .29-.3.29-.4.48q-558.7-23-1117.2-19.38Q407.55 214.87 0 231.65V90.51z" fill="url(#gradient)" data-name="Layer 1-2" /></g></g></svg>

  </Layout >
}

export default injectIntl(Iletisim)
